<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="botaoDepoisAcaoTitulo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="v-btn--simple"
            icon
            v-bind="attrs"
            @click="mostraFiltro = !mostraFiltro"
            v-on="on"
          >
            <v-icon>{{ mostraFiltro? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro') }}</span>
      </v-tooltip>
    </template>
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="hidden-sm-and-down justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.observacao }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td
          v-money="{simbolo:'R$', valor:item.item.valor}"
          class="hidden-sm-and-down justify-left"
        />
        <td
          v-data-formatada
          class="hidden-sm-and-down justify-left"
        >
          {{ item.item.dataCompra }}
        </td>
        <td
          class="hidden-sm-and-down justify-left"
        >
          {{ item.item.fornecedor }}
        </td>
        <td
          class="hidden-sm-and-down justify-left"
        >
          {{ item.item.telefone1 }}
        </td>
        <td
          class="hidden-md-and-up justify-center"
          @click="editar(item.item)"
        >
          <div class="observacao">
            <v-icon small>
              mdi-pencil
            </v-icon>
            {{ item.item.observacao }}
          </div>
        </td>
        <td
          class="hidden-md-and-up justify-center"
        >
          <div class="valor">
            <v-icon small>
              mdi-database-search
            </v-icon>
            <span v-money="{simbolo:'R$', valor: item.item.valor}" />
          </div>
          <div class="dataCompra">
            <v-icon small>
              mdi-calendar-text
            </v-icon>
            <span v-data-formatada>{{ item.item.dataCompra }}</span>
          </div>
          <div class="fornecedor">
            <v-icon small>
              mdi-database-search
            </v-icon>
            {{ item.item.fornecedor }}
          </div>
          <div class="telefone1">
            <v-icon
              v-if="item.item.telefone1"
              small
            >
              mdi-phone-classic
            </v-icon>
            {{ item.item.telefone1 }}
          </div>
        </td>
        <td v-if="!idEmpresaCliente || idEmpresaCliente < 0">
          <span v-if="item.item.EmpresaCliente !== null">
            {{ item.item.EmpresaCliente.nome }}
          </span>
        </td>
      </tr>
    </template>

    <template slot="antesDaDataTable">
      <v-row v-show="mostraFiltro">
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="6"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
          />
        </v-col>
        <v-col
          :cols="!idEmpresaCliente || idEmpresaCliente < 0 ? 6 : 12"
          sm="6"
        >
          <v-autocomplete
            v-model="autoCompleteFornecedorModel"
            :readonly="!empresaClienteModelId"
            :items="autoCompleteFornecedorItens"
            :loading="autoCompleteFornecedorLoading"
            :search-input.sync="autoCompleteFornecedorCampoBusca"
            :filter="filterFornecedorAc"
            :label="$t('DespesaForm.fornecedor')"
            hide-no-data
            item-text="nome"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="ml-3"
          sm="4"
          cols="5"
        >
          <v-menu
            ref="menuDataCompra"
            v-model="menuDataCompra"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataCompraFormatted"
                :label="$t('DespesaForm.dataCompra')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                @blur="filtro.dataCompra = parseDate(dataCompraFormatted)"
                v-on="on"
                @click:append="filtro.dataCompra = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataCompra"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataCompra = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="5"
          sm="4"
        >
          <v-menu
            ref="menuDataCompraFim"
            v-model="menuDataCompraFim"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataCompraFimFormatted"
                :label="$t('DespesaForm.dataCompraFim')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                @blur="filtro.dataCompraFim = parseDate(dataCompraFimFormatted)"
                v-on="on"
                @click:append="filtro.dataCompraFim = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataCompraFim"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataCompraFim = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="1"
          sm="3"
        >
          <v-btn
            class="v-btn--simple"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.smAndDown"
            @click="buscar"
          >
            <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <hr v-show="mostraFiltro">
    </template>
  </crud-list>
</template>

<script>

  import DespesasService from '@/services/DespesasService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'
  import DateUtils from "../utils/DateUtils"
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete"
  import EmpresaClientesService from '../services/EmpresaClientesService'
  import FornecedoresService from '../services/FornecedoresService'

  export default {
    components: {
      EmpresaClienteAutoComplete,
    },
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      itemRemover: {},
      mostraFiltro: true,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      menuDataCompra: false,
      menuDataCompraFim: false,
      fornecedorModelId: null,
      autoCompleteFornecedorModel: null,
      autoCompleteFornecedorItens: [],
      autoCompleteFornecedorLoading: false,
      autoCompleteFornecedorCampoBusca: '',
      filtro: {
        dataCompra: null,
        dataCompraFim: null,
        idEmpresaCliente: null,
        idFornecedor: null
      },
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('DespesaForm.tituloPesquisa')
      },
      dataCompraFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataCompra);
      },
      dataCompraFimFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataCompraFim);
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('DespesaForm.observacao'),
            value: 'observacao',
            sortable: false
          },
          {
            text: this.$i18n.t('DespesaForm.valor'),
            value: 'valor',
            sortable: false
          },
          {
            text: this.$i18n.t('DespesaForm.dataCompra'),
            value: 'dataCompra',
            sortable: false,
            class: "hidden-sm-and-down"
          },
          {
            text: this.$i18n.t('DespesaForm.fornecedor'),
            value: 'fornecedor',
            sortable: false,
            class: "hidden-sm-and-down"
          },
          {
            text: this.$i18n.t('DespesaForm.telefone1'),
            value: 'telefone1',
            sortable: false,
            class: "hidden-sm-and-down"
          }
        ]

        if (!this.idEmpresaCliente || this.idEmpresaCliente < 0) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      }
    },
    watch: {
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.empresaClienteModelId = null
          this.filtro.idEmpresaCliente = null
          this.autoCompleteFornecedorModel= null
          this.autoCompleteFornecedorItens= []
          this.autoCompleteFornecedorLoading= false
          this.autoCompleteFornecedorCampoBusca= ''
        } else {
          this.empresaClienteModelId = val.id
          this.filtro.idEmpresaCliente = val.id
        }
      },
      autoCompleteFornecedorModel(val) {
        if (val === null || !val) {
          this.fornecedorModelId = null
          this.filtro.idFornecedor = null
        } else {
          this.fornecedorModelId = val.id
          this.filtro.idFornecedor = val.id
        }
      },
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      },
      autoCompleteFornecedorCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaFornecedor(val)
      },
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    mounted() {

      if(localStorage.getItem('pagination-despesa-list')){
        this.pagination = JSON.parse(localStorage.getItem('pagination-despesa-list'))
      }

      if(localStorage.getItem('filtro-despesa-list') != null){
        this.filtro = JSON.parse(localStorage.getItem('filtro-despesa-list'))
        if(this.filtro.idEmpresaCliente && this.filtro.idEmpresaCliente > 0){
          EmpresaClientesService.findById(this.filtro.idEmpresaCliente)
            .then((res) => {
              this.empresaClienteModel = res.data
              this.started = true
              this.buscar()
            })
        }else{
          this.started = true
          this.buscar()
        }

      }else{
        const dt = new Date();
        const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        this.filtro.dataEvento = `${dt.getFullYear()}-${month}-${day}`
        this.started = true
        this.buscar()
      }

    },
    methods: {

      buscaFornecedor (param) {
        this.autoCompleteFornecedorLoading = true
        FornecedoresService.buscaFornecedoresPorParam(
          param,
          this.filtro.idEmpresaCliente
        )
          .then((fornecedores) => {
            this.autoCompleteFornecedorItens = fornecedores.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos fornecedores ' + error
            })
          })
          .finally(() => {
            this.autoCompleteFornecedorLoading = false
          })
      },
      filterFornecedorAc (item, queryText, itemText) {
        return true
      },

      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          idEmpresaCliente: this.idEmpresaCliente,
          observacao: pesquisa
        }
        DespesasService.findByParam(param, this.pagination)
          .then((retorno) => {
            if (retorno && retorno.data && retorno.data.rows) {
              this.items = retorno.data.rows
            }
            if (retorno && retorno.data) {
              this.totalRegistros = retorno.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true
        this.filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente
        
        this.filtro.idFornecedor = this.fornecedorModelId

        const str = JSON.stringify(this.filtro)
        localStorage.setItem('filtro-despesa-list', str)
        localStorage.setItem('pagination-despesa-list', JSON.stringify(this.pagination))

        DespesasService.findByParam(this.filtro, this.pagination)
          .then((retorno) => {
            if (retorno && retorno.data && retorno.data.rows) {
              this.items = retorno.data.rows
            }
            if (retorno && retorno.data) {
              this.totalRegistros = retorno.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar as despesas cadastradas: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      parseDate(date) {
        return DateUtils.parseDate(date);
      },
      novo () {
        this.$router.push({
          path: '/despesasForm'
        })
      },
      editar (despesa) {
        if (despesa && despesa.id) {
          const url = '/despesasForm/' + despesa.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
</style>

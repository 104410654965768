import Api from '@/services/Api'

export default {
  insert (despesa) {
    return Api().post('despesa', despesa)
  },
  update (despesa) {
    return Api().put('despesa', despesa)
  },
  delete (despesa) {
    return Api().delete(`despesa?id=${despesa.id}`, despesa)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('despesasParam', param)
  },
  findById (despesa) {
    const url = `despesa/${despesa.id}`
    return Api().get(url)
  },
  somaTotalMes (idEmpresaCliente, mes, ano) {
    const url = `despesa/resumo/${idEmpresaCliente}/${ano}/${mes}`
    const api = Api()
    return api.get(url)
  }

}
